import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { aliasRef } from '../Config/firebase'
import SignUp from './Admin/SignUp'
import Loading from './Loading'
import aliasBackground from '../Assets/home/aliasBackground.webp'
import { loadDoc } from '../Utils/firebaseUtils'

const ProfileAlias = () => {
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [showSignUp, setShowSignUp] = useState(false)
    let params = useParams()

    useEffect(() => {
        loadDoc(aliasRef,["alias", "==", params.aliasID],initData)
    }, [])

    const initData = async (data) =>{
        if(!data){
            navigate('/404')
        }
        if (data && data.username) {
            navigate(`/${data.username}`)
        }
        setIsLoadingData(false)
    }
    
    return (
        <>

            {
                isLoadingData ? <Loading /> : showSignUp ? <SignUp alias={params.aliasID} /> :
                    <div className='loginBody' style={{backgroundImage:`url(${aliasBackground})`, backgroundSize:"cover"}}>
                        <div className="form-signin shadow" style={{"margin":"16px",backgroundColor:"white"}}>
                            <div>
                                <h2>Welcome to Tappo</h2>
                                <br />
                                <input type="button" style={{"width":"100%", padding:"8px", borderRadius:"0px"}} value="Create new Profile" className='btn btn-primary' onClick={() => setShowSignUp(true)} />
                                <br /><br />
                                <input type="button" style={{"width":"100%", padding:"8px", borderRadius:"0px"}} value="Add alias to already existing account" className='btn btn-primary' onClick={() => { navigate(`/user/${params.aliasID}`) }} />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ProfileAlias