import React from 'react'
import { Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import icon128 from "../Assets/icons/icon128.webp"
import linkedin from "../Assets/linkedin.png";
import facebook from "../Assets/facebook.png";
import whatsapp from "../Assets/whatsapp.webp";
import mhg from "../Assets/mhg.webp"
import sgs from "../Assets/sgs.webp"

const Loading = ({ message }) => {
  let params = useParams()
  return (
    <div className="h111">
      <div className='text-center h222'>
        <Image src={getloaderImg(params.companyID)} fluid style={{ height: "96px", margin: "8px" }} />
        <ScaleLoader /><br />{message}</div>
    </div>

  )
}

const getloaderImg = (companyID) => {
  if (!companyID)
    return icon128
  switch (companyID) {
    case "FB":
      return facebook
    case "WA":
      return whatsapp
    case "LN":
      return linkedin
    case "mhg":
      return mhg
    case "sgs":
      return sgs
    default:
      return icon128

  }
}

export default Loading