import { getDocs, query, where } from "firebase/firestore"


export const loadDoc = async (docRef, whereCondition, callBack) => {
    const q = query(docRef, where(...whereCondition))
    const querySnapshot = await getDocs(q)
    let data = null
    let id = null
    querySnapshot.forEach((doc) => {
        data = doc.data()
        id = doc.id
    })
    callBack(data, id)
}

export const loadDocs = async (docRef, whereCondition, callBack) => {
    var q = query(docRef)
    if (whereCondition.length > 0)
        q = query(docRef, where(...whereCondition))
    const querySnapshot = await getDocs(q)
    let data = []
    querySnapshot.forEach((doc) => {
        data = [...data, { ...doc.data(), "id": doc.id }]
    })
    callBack(data)
}