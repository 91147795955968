import React from 'react'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/UserContext';
import "../../Styles/login.css"

const SignUp = ({alias}) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [rePassword, setRePassword] = useState();
    const {user , signUp} = useAuth();

    const navigate = useNavigate();

    const handleSignUp = () => {
        if(email && password && rePassword){
            signUp(email,password);
            let url = `/user/new/${alias}`;
            navigate(url);
        }
        // signUp("test@gmail.com","12345678")
    }
    return (
        <>
            {user ? <p>{user.email}</p> : <div className='loginBody'>
                <div className="form-signin">
                    {/* <img className="mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72" /> */}
                    <h1 className="h3 mb-3 font-weight-normal">Tappo Sign up</h1>
                    <p>Alias : {alias}</p>
                    <input type="email" id="email" className="form-control" placeholder="Please enter your Email address" required onChange={event => setEmail(event.target.value)} />
                    <input type="password" id="password" className="form-control" placeholder="Enter Password" required onChange={event => setPassword(event.target.value)} />
                    <input type="password" id="rePassword" className="form-control" placeholder="Enter Password again" required onChange={event => setRePassword(event.target.value)} />
                    <button className="btn btn-lg btn-primary btn-block" onClick={handleSignUp}>Sign in</button>
                    <p className="mt-5 mb-3 text-muted">&copy; 2022</p>
                </div>
            </div>}
            {/* <div onClick={() => login("test@mail.com","123456")}>Login</div>
    <div onClick={() => updateData(user)}>upadte data</div> */}
        </>
    )
}

export default SignUp