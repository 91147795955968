import React from 'react'
import '../Styles/404.css'

const H404 = ({message}) => {
  return (
    <div id="main">
    	<div className="fof">
        		<h1>{message ? message : "404 page not found"}</h1>
    	</div>
</div>
  )
}

export default H404