import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { authentication, db, authentication as auth2 } from '../Config/firebase';
import { addDoc, collection } from "firebase/firestore";

export const UserContext = createContext({
    user: null,
    isLoggingIn: null,
    login: () => Promise,
    logout: () => Promise,
    signUp: () => Promise,
    signUpWithoutLogin: () => Promise,
});

export const useAuth = () => useContext(UserContext)

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggingIn,setIsLoggingIn] = useState(false)

    const profileRef = collection(db, "profileData")
    const usersRef = collection(db, "users")

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authentication, user => {
            setUser(user);
        })
        return () => unsubscribe();
    }, [])

    const loginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(authentication, provider).then((res) => {
            console.log('loggedin');
        }).catch((err) => {
            console.log(err)
        });
    }

    const loginWithEmail = (email, password) => {
        signInWithEmailAndPassword(authentication, email, password).then(res => {
            console.log(res)
            setIsLoggingIn(false)
        }).catch(err => {
            console.log(err)
            setIsLoggingIn(false)
            alert("Wrong username or password. Please try again")
        });
    }

    const signUpWithEmail = (email, password) => {
        createUserWithEmailAndPassword(authentication, email, password).then(res => {
            let profileJson = {
                "email": res.user.email,
            }
            let userJson = {
                "email": res.user.email,
                "accessLevel": 0,
            }
            const doc = addDoc(profileRef, profileJson)
            const userDoc = addDoc(usersRef, userJson)
        }).catch(err => {
            console.log(err)
        })
    }

    const signUpWithEmailWithoutLogin = (email, password) => {
        createUserWithEmailAndPassword(auth2, email, password).then(res => {
        }).catch(err => {
            console.log(err)
        })
    }

    const login = (email, password) => {
        setIsLoggingIn(true)
        return loginWithEmail(email, password);
    }

    const signUp = (email, password) => {
        return signUpWithEmail(email, password);
    }

    const signUpWithoutLogin = (email,password) => {
        return signUpWithEmailWithoutLogin(email,password)
    }

    const logout = () => {
        authentication.signOut().then(() => {
        }).catch((err) => {
            console.log(err)
        })
    }


    const value = {
        user,
        isLoggingIn,
        login,
        logout,
        signUp,
        signUpWithoutLogin,
    }

    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
};


export default UserContextProvider;