import React, { Suspense } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Styles/Style.css'
import { Route, Routes } from 'react-router-dom'
import H404 from './Components/H404'
import ProfileAlias from './Components/ProfileAlias'
import Loading from './Components/Loading'

const Home = React.lazy(() => import('./Components/Home'))
const ProfileView = React.lazy(() => import('./Components/ProfileView'))
const CompanyView = React.lazy(() => import('./Components/CompanyView'))
// const AddProfile = React.lazy(() => import('./Components/SuperAdmin/AddProfile'))
const Admin = React.lazy(() => import('./Components/Admin'))
const MenuView = React.lazy(() => import('./Components/Menu/MenuView'))
const ImageUploadWithCrop = React.lazy(() => import('./Components/Experimental/ImageUploadWithCrop'))


function App() {
  return (
    <Routes>
      <Route path='/' element={<Suspense fallback={<Loading />}><Home /></Suspense>} />
      <Route path="/:userID/menu" element={<Suspense fallback={<Loading />}><MenuView /></Suspense> } />
      <Route path="404" element={ <H404 /> } />
      <Route path=":userID" element={<Suspense fallback={<Loading />}><ProfileView /></Suspense> } />
      <Route path="c/:companyID/:userID" element={<Suspense fallback={<Loading />}><ProfileView /></Suspense> } />
      <Route path="c/:companyID" element={<Suspense fallback={<Loading />}><CompanyView /></Suspense> } />
      <Route path="a/:aliasID" element={<ProfileAlias />} />
      <Route path="*" element={  <H404 /> } />
      <Route path="user" element={<Suspense fallback={<Loading />}><Admin /></Suspense>} />
      {/* <Route path="dashboard" element={<Suspense fallback={<Loading />}><AddProfile /></Suspense>} /> */}
      {/* <Route path="register" element={<SignUp />} /> */}
      <Route path="user/:aliasID" element={<Suspense fallback={<Loading />}><Admin /></Suspense>} />
      <Route path="user/new/:aliasID" element={<Suspense fallback={<Loading />}><Admin newAcc="true" /></Suspense>} />
      <Route path='experimental/imageupload' element={<Suspense fallback={<Loading />}><ImageUploadWithCrop /></Suspense>} />
      {/* <Route path="create/alias" element={ <CreateAlias /> } /> */}
    </Routes>
  );
}


export default App
